<template>
    <v-main>
        <template-select-toolbar :duct="duct" :prjName="prjName" v-on:child-event="tmplNameEvent"></template-select-toolbar>

        <router-view :duct="duct" :prjName="prjName" :tmplName="tmplName"></router-view>
    </v-main>
</template>

<script>
import TemplateSelectToolbar from './TemplateSelectToolbar'
//import ResponseTable from './ResponseTable'

export default {
    data: () => ({
        tmplName: "None"
    }),
    components: {
        TemplateSelectToolbar,
        //ResponseTable
    },
    props: ["duct", "prjName"],
    methods: {
        tmplNameEvent(tmplName) {
            this.tmplName = tmplName;
        }
    }
}
</script>
